import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-vittaa-typo',
  templateUrl: './vittaa-typo.component.html',
  styleUrls: ['./vittaa-typo.component.scss']
})
export class VittaaTypoComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
