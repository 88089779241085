import { trigger, state, style, transition,animate, group, query, stagger, keyframes } from '@angular/animations';

export const SlideInOutAnimation = [
    trigger('slideInOut', [
        state('in', style({
            'transform': 'translateX(100%)',  'visibility': 'hidden', 'box-shadow' : '0px 2px 4px 0px rgba(17,17,17,0.04)'
        })),
        state('out', style({
            'transform': 'translateX(0%)', 'visibility': 'visible', 'box-shadow' : '0px 16px 32px -1px rgba(17,17,17,0.18)'
            
        })),
        transition('in => out', [group([
            
            animate('400ms ease-in-out', style({
                'transform': 'translateX(0%)'
            })),
            animate('1ms ease-in-out', style({
                'visibility': 'visible'
            })),
            animate('600ms ease-in-out', style({
                'box-shadow' : '0px 16px 32px -1px rgba(17,17,17,0.18)'
            }))
        ]
        )]),
        transition('out => in', [group([
            animate('1ms ease-in-out', style({
                'visibility': 'visible'
            })),
            animate('400ms ease-in-out', style({
                'transform': 'translateX(100%)'
            })),
            animate('200ms ease-in-out', style({
                'box-shadow' : '0px 2px 4px 0px rgba(17,17,17,0.04)'
            }))
            
        ]
        )])
    ]),
]

export const menuTopAnimation = [
    trigger('menuTop', [
        state('in', style({
            'transform' : 'translateY(0px) translateX(0) rotate(0deg)',
            'background-color' : '#151515'
        })),
        state('out', style({
            'transform': 'translateY(3px) translateX(0) rotate(45deg)',
            'background-color' : 'rgba(21,21,21,.54)'
        })),
        transition('in => out', [group([
            animate('300ms ease-in-out', style({
                'transform': 'translateY(3px) translateX(0) rotate(45deg)',
                'background-color' : 'rgba(21,21,21,.54)'
            }))
        ]
        )]),
        transition('out => in', [group([
            
            animate('300ms ease-in-out', style({
                'transform': 'translateY(0px) translateX(0) rotate(0deg)',
                'background-color' : '#151515'
            }))
        ]
        )])
    ]),
]

export const menuBottomAnimation = [
    trigger('menuBottom', [
        state('in', style({
            'transform' : 'translateY(5px) translateX(0) rotate(0deg)',
            'background-color' : '#151515'
        })),
        state('out', style({
            'transform': 'translateY(1px) translateX(0) rotate(-45deg)',
            'background-color' : 'rgba(21,21,21,.54)'
        })),
        transition('in => out', [group([
            animate('300ms ease-in-out', style({
                'transform': 'translateY(1px) translateX(0) rotate(-45deg)',
                'background-color' : 'rgba(21,21,21,.54)'
            }))
        ]
        )]),
        transition('out => in', [group([
            
            animate('300ms ease-in-out', style({
                'transform': 'translateY(5px) translateX(0) rotate(0deg)',
                'background-color' : '#151515'
            }))
        ]
        )])
    ]),
]
export const navMenuAnimation = [
    trigger('navMenu', [
        state('in', style({
            'display' : 'inline-block'
        })),
        state('out', style({
            'display' : 'none'
        })),
        transition('in => out', [group([
            animate('3ms ease-in-out', style({
                'display' : 'none'
            }))
        ]
        )]),
        transition('out => in', [group([
            
            animate('3ms ease-in-out', style({
                'display' : 'inline-block'
            }))
        ]
        )])
    ]),
]
export const navCloseAnimation = [
    trigger('navClose', [
        state('in', style({
            'display' : 'none'
        })),
        state('out', style({
            'display' : 'inline-block'
        })),
        transition('in => out', [group([
            animate('3ms ease-in-out', style({
                'display' : 'inline-block'
            }))
        ]
        )]),
        transition('out => in', [group([
            
            animate('3ms ease-in-out', style({
                'display' : 'none'
            }))
        ]
        )])
    ]),
]