import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-print-slider-tpflyer',
  templateUrl: './print-slider-tpflyer.component.html',
  styleUrls: ['./print-slider-tpflyer.component.scss']
})
export class PrintSliderTpflyerComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
