import { InjectionToken, NgModule } from "@angular/core";
import {
  Routes,
  RouterModule,
  ExtraOptions,
  ActivatedRouteSnapshot,
} from "@angular/router";

import { HomeComponent } from "./pages/home/home.component";
import { WorkComponent } from "./pages/work/work.component";
import { AboutComponent } from "./pages/about/about.component";
import { WorkListComponent } from "./shared/blocks/work-list/work-list.component";
import { VittaaAppComponent } from "./pages/vittaa-app/vittaa-app.component";
import { EdenKnivesComponent } from "./pages/eden-knives/eden-knives.component";
import { KatWebsiteComponent } from "./pages/kat-website/kat-website.component";
import { GrailerComponent } from "./pages/grailer/grailer.component";
import { LogoDesignComponent } from "./pages/logo-design/logo-design.component";
import { PrintComponent } from "./pages/print/print.component";
import { DanviComponent } from "./pages/danvi/danvi.component";
import { Danvi2Component } from "./pages/danvi2/danvi2.component";
import { OndergrondsverbondComponent } from "./pages/ondergrondsverbond/ondergrondsverbond.component";

const externalUrlProvider = new InjectionToken("externalUrlRedirectResolver");
const deactivateGuard = new InjectionToken("deactivateGuard");

export const routes: Routes = [
  { path: "", component: HomeComponent },
  { path: "home", pathMatch: "full", component: HomeComponent },
  { path: "work", pathMatch: "full", component: WorkComponent },
  { path: "random", pathMatch: "full", component: WorkComponent },
  { path: "work-list", pathMatch: "full", component: WorkListComponent },

  { path: "about", pathMatch: "full", component: AboutComponent },
  { path: "vittaa-app", pathMatch: "full", component: VittaaAppComponent },
  { path: "eden-knives", pathMatch: "full", component: EdenKnivesComponent },
  { path: "kat-website", pathMatch: "full", component: KatWebsiteComponent },
  { path: "grailer", pathMatch: "full", component: GrailerComponent },
  { path: "logo-design", pathMatch: "full", component: LogoDesignComponent },
  { path: "print", pathMatch: "full", component: PrintComponent },
  { path: "danvi", pathMatch: "full", component: DanviComponent },
  { path: "danvi2", pathMatch: "full", component: Danvi2Component },
  {
    path: "ondergrondsverbond",
    pathMatch: "full",
    component: OndergrondsverbondComponent,
  },

  {
    path: "externalRedirect",
    canActivate: [externalUrlProvider],
    component: HomeComponent,
  },

  { path: "**", component: HomeComponent },
];

const routerOptions: ExtraOptions = {
  anchorScrolling: "enabled",
  onSameUrlNavigation: "reload",
  scrollPositionRestoration: "top",
};

@NgModule({
  providers: [
    {
      provide: externalUrlProvider,
      useValue: (route: ActivatedRouteSnapshot) => {
        const externalUrl = route.paramMap.get("externalUrl");
        window.open(externalUrl, "_blank");
      },
    },
    {
      provide: deactivateGuard,
      useValue: () => {
        console.log("Guard function is called!");

        return false;
      },
    },
  ],
  imports: [RouterModule.forRoot(routes, routerOptions)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
