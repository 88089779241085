import { Component } from "@angular/core";

@Component({
  selector: "app-danvi-colours2",
  templateUrl: "./danvi-colours2.component.html",
  styleUrls: ["./danvi-colours2.component.scss"],
})
export class DanviColours2Component {
  colours = [
    {
      swatch: "#3E5742",
      title: "Celtic",
      code: "#3E5742",
    },

    {
      swatch: "#8C956B",
      title: "Avocado",
      code: "#8C956B",
    },
    {
      swatch: "#91614B",
      title: "Spicy Mix",
      code: "#91614B",
    },
    {
      swatch: "#F49846",
      title: "Sea Buckthorn",
      code: "#F49846",
    },
  ];

  constructor() {}
}
