import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class ContentService {
  pages: Object = {
    "vittaa-app": {
      title: "vittaa",
      description:
        "Designing an online meet up platform for people who want to run, bike or hike together.",
    },

    "kat-website": {
      title: "knivesandtools",
      description:
        "Visual design for an international web shop offering a wide range of kitchen and outdoor tools. ",
    },

    grailer: {
      title: "grailer",
      description: "Branding a new private label for grail pocket knives.",
    },

    "logo-design": {
      title: "logo design",
      description:
        "A small selection of logos and workmarks I made through the years.",
    },

    print: {
      title: "print design",
      description: "Brochures, magazines, flyers. Print is still relevant.",
    },

    danvi: {
      title: "danvi",
      description: "Creating a visual identity for a new coffee brand. ",
    },
    danvi2: {
      title: "danvi",
      description: "Creating a visual identity for a new coffee brand. ",
    },
    ondergrondsverbond: {
      title: "ondergronds verbond",
      description: "Uniting diverse dance events. ",
    },
  };

  constructor() {}
}
