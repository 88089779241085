import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ov-typo',
  templateUrl: './ov-typo.component.html',
  styleUrls: ['./ov-typo.component.scss']
})
export class OvTypoComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
