import { Component, OnInit } from '@angular/core';
import { Meta } from '@angular/platform-browser';  



@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  constructor( private meta: Meta) { 

    this.meta.addTags([
      { name: 'title', content: 'Lemonfish, multidisciplinair designer uit Ede, Gelderland. Creatie van brand identity, webdesign en print.' },

      { name: 'description', content: 'Arnoud Hettinga multidisciplinair designer met meer dan 20 jaar ervaring in visuele identiteit en webdesign, app-design, logo-design en print.' }
    ]); 

  }

  ngOnInit() {
  }

}
