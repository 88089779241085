import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-danvi-branding2",
  templateUrl: "./danvi-branding2.component.html",
  styleUrls: ["./danvi-branding2.component.scss"],
})
export class DanviBranding2Component implements OnInit {
  constructor() {}

  ngOnInit() {}
}
