import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ov-branding',
  templateUrl: './ov-branding.component.html',
  styleUrls: ['./ov-branding.component.scss']
})
export class OvBrandingComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
