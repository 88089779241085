import { Component, OnInit } from '@angular/core';
import { Meta } from '@angular/platform-browser';  


@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss']
})
export class AboutComponent implements OnInit {

  constructor( private meta: Meta) { 

    this.meta.addTags([
      { name: 'title', content: 'Arnoud Hettinga multidisciplinair ontwerper uit Ede.' },

      { name: 'description', content: 'Arnoud Hettinga multidisciplinair designer met meer dan 20 jaar ervaring in visuele identiteit en design voor print en digitaal.' }
    ]); 

  }

  ngOnInit() {
  }

}
