import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-grailer-colours",
  templateUrl: "./grailer-colours.component.html",
  styleUrls: ["./grailer-colours.component.scss"],
})
export class GrailerColoursComponent implements OnInit {
  colours = [
    {
      swatch: "#5F5C59",
      title: "Chicago",
      code: "#5F5C59",
    },
    {
      swatch: "#B8AFA5",
      title: "Nomad",
      code: "#B8AFA5",
    },
    {
      swatch: "#C7CBC4",
      title: "Pumice",
      code: "#C7CBC4",
    },
    {
      swatch: "#FE5F55",
      title: "Sunset Orange",
      code: "#FE5F55",
    },
  ];

  constructor() {}

  ngOnInit() {}
}
