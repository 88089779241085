import { Component } from '@angular/core';

@Component({
  selector: 'app-kat-slider-logo',
  templateUrl: './kat-slider-logo.component.html',
  styleUrls: ['./kat-slider-logo.component.scss']
})
export class KatSliderLogoComponent  {

  logos = [
    { background: '#F4F6F7',
      svg: "assets/img/kat/kat-logo-dark.svg" 
    },
    { background: '#111418',
      svg: "assets/img/kat/kat-logo-light.svg" 
    },
  ];

  breakpoints = [
    {
      breakpoint: 9999,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1
      }
    }, 
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1
      }
    }
  ];

  slideConfig = { 
    "dots": true, 
    "arrows": true,
    "responsive": this.breakpoints 
  };

  breakpoint() {
    console.log('breakpoint');
  }

  constructor() { }

  ngOnInit() {
  }

}
