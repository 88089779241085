import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-logo-animation",
  templateUrl: "./logo-animation.component.html",
  styleUrls: ["./logo-animation.component.scss"],
})
export class LogoAnimationComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
