import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-grailer-slider-mobile",
  templateUrl: "./grailer-slider-mobile.component.html",
  styleUrls: ["./grailer-slider-mobile.component.scss"],
})
export class GrailerSliderMobileComponent implements OnInit {
  slides = [
    { img: "assets/img/grailer/grailer_home_mob.webp" },
    { img: "assets/img/grailer/grailer_g3_mob.webp" },
    { img: "assets/img/grailer/grailer_about_mob.webp" },
  ];

  breakpoints = [
    {
      breakpoint: 9999,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 2,
      },
    },
    {
      breakpoint: 1600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 800,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        centerMode: true,
        centerPadding: "0",
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ];

  slideConfig = {
    dots: true,
    responsive: this.breakpoints,
  };

  slickInit(e) {
    console.log("slick initialized");
  }

  breakpoint(e) {
    console.log("breakpoint");
  }

  afterChange(e) {
    console.log("afterChange");
  }

  beforeChange(e) {
    console.log("beforeChange");
  }

  constructor() {}

  ngOnInit() {}
}
