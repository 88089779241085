import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-work-list",
  templateUrl: "./work-list.component.html",
  styleUrls: ["./work-list.component.scss"],
})
export class WorkListComponent implements OnInit {
  order: number[];

  images = [
    {
      img: "assets/img/random/random_01.webp",
      description: "Logo for packaging Grailer G2 pocket knife",
    },
    {
      img: "assets/img/random/random_03.webp",
      description: "Overall packaging design for Skerper sharpening products",
    },
    {
      img: "assets/img/random/random_04.webp",
      description: "Logo for Eden Takara Japanese kitchen knives collection",
    },
    {
      img: "assets/img/random/random_05.webp",
      description:
        "Packaging for Eden Classic Damast kitchen knives collection",
    },
    {
      img: "assets/img/random/random_06.webp",
      description: "Logo for Eden Dento Japanese kitchen knives collection",
    },
    {
      img: "assets/img/random/random_07.webp",
      description: "LFG Gaming platform app search results screen",
      concept: "concept",
    },
    {
      img: "assets/img/random/random_08.webp",
      description: "Label for Habibah Fashion",
    },
    {
      img: "assets/img/random/random_09.webp",
      description: "Letterpress card for Habibah Fashion",
    },
    {
      img: "assets/img/random/random_10.webp",
      description: "Logo Serious Summer Sale for Knivesandtools website banner",
    },
    {
      img: "assets/img/random/random_11.webp",
      description: "Plixy Payment app overview requests screen",
      concept: "concept",
    },
    {
      img: "assets/img/random/random_12.webp",
      description: "Plixy Payment app log in screen",
      concept: "concept",
    },
    {
      img: "assets/img/random/random_14.webp",
      description: "Homepage for online sports nutrition supplier",
      concept: "concept",
    },
    {
      img: "assets/img/random/random_15.webp",
      description: "Homepage for online sports nutrition supplier",
      concept: "concept",
    },
    {
      img: "assets/img/random/random_16.webp",
      description: "Knivesandtools magazine spread",
    },
    {
      img: "assets/img/random/random_17.webp",
      description: "Knivesandtools magazine spread",
    },
    {
      img: "assets/img/random/random_18.webp",
      description: "Knivesandtools magazine spread",
    },
    {
      img: "assets/img/random/random_19.webp",
      description: "Knivesandtools magzaine pages",
    },
    {
      img: "assets/img/random/random_20.webp",
      description: "Have a knives day sticker for Knivesandtools sticker pack",
    },
    {
      img: "assets/img/random/random_21.webp",
      description: "Wrapping paper for Knivesandtools merchandise articles",
    },
    {
      img: "assets/img/random/random_22.webp",
      description: "Logo for pocket knives newsletter Knivesandtools",
      concept: "concept",
    },
    {
      img: "assets/img/random/random_23.webp",
      description:
        "Part one of the lettering on a glass office wall for Commplot",
    },
    {
      img: "assets/img/random/random_24.webp",
      description:
        "Part two of the lettering on a glass office wall for Commplot",
    },
    {
      img: "assets/img/random/random_25.gif",
      description:
        "Swipe Up Giphy for Knivesandtools to be used on Instagram Stories",
    },
    {
      img: "assets/img/random/random_26.webp",
      description: "Packaging label for Danvi Specialty Coffee",
      concept: "concept",
    },
    {
      img: "assets/img/random/random_27.webp",
      description: "Icon set for Eden Binoculars",
    },
    {
      img: "assets/img/random/random_28.webp",
      description: "Logo for Eden Sugoi Japanese kitchen knives collection",
    },
    {
      img: "assets/img/random/random_29.webp",
      description: "Label set for Knivesandtools Christmas deals",
    },
    {
      img: "assets/img/random/random_31.webp",
      description: "Skerper logo on a sharpening stone",
    },
    {
      img: "assets/img/random/random_32.webp",
      description: "Design for a for a 'Sorry' postcard for Knivesandtools",
    },
    {
      img: "assets/img/random/random_33.webp",
      description: "Small brochure for kitchen knives line-ups",
    },
    {
      img: "assets/img/random/random_34.gif",
      description:
        "Knivesandtools Giphy sticker to be used on Instagram Stories",
    },
    {
      img: "assets/img/random/random_35.gif",
      description: "Packaging design for a Skerper Basic Sharpening set",
    },
    {
      img: "assets/img/random/random_36.gif",
      description: "404 page animation for the Knivesandtools website",
    },
    {
      img: "assets/img/random/random_37.webp",
      description: "Illustration for an ICT Service for Brainforce",
    },
    {
      img: "assets/img/random/random_38.webp",
      description: "Illustration for an ICT Service for Brainforce",
    },
    {
      img: "assets/img/random/random_39.webp",
      description: "Illustration for an ICT Service for Brainforce",
    },
    {
      img: "assets/img/random/random_40.webp",
      description: "Illustration for an ICT Service for Brainforce",
    },
    {
      img: "assets/img/random/random_41.webp",
      description: "Close up of Eden Dento logo on a Japanese Santoku knife",
    },
    {
      img: "assets/img/random/random_42.webp",
      description:
        "Close up of Eden Classic Damast engraving on a Chef's knife",
    },
    {
      img: "assets/img/random/random_43.gif",
      description: "Illustrations for Plixy Payment Service",
      concept: "concept",
    },
    {
      img: "assets/img/random/random_44.webp",
      description: "Illustration of a beheaded animal running in the wild",
    },
    {
      img: "assets/img/random/random_45.png",
      description: "Badge illustration for The Holiday Season campaign",
    },
    {
      img: "assets/img/random/random_46.png",
      description: "Badge illustration for The Holiday Season campaign",
    },
    {
      img: "assets/img/random/random_47.png",
      description: "Badge illustration for The Holiday Season campaign",
    },
    {
      img: "assets/img/random/random_48.png",
      description: "Badge illustration for The Holiday Season campaign",
    },
    {
      img: "assets/img/random/random_49.png",
      description: "Badge illustration for The Holiday Season campaign",
    },
    {
      img: "assets/img/random/random_50.png",
      description: "Badge illustration for The Holiday Season campaign",
    },
    {
      img: "assets/img/random/random_51.png",
      description: "N",
    },
    {
      img: "assets/img/random/random_52.webp",
      description: "Illustration for Stand-up Comedy event",
    },
    {
      img: "assets/img/random/random_53.webp",
      description: "Logo design for Have a Knives Day event",
    },
    {
      img: "assets/img/random/random_54.webp",
      description: "Shirt design for Have a Knives Day event",
    },
    {
      img: "assets/img/random/random_55.webp",
      description: "Logo design for a life style brand on a beanie",
    },
    {
      img: "assets/img/random/random_56.webp",
      description: "Design on a mug",
    },
  ];

  ngOnInit(): void {
    this.order = this.images.map((n, index) => index); //create an array [0,1,2,3..]
    this.order.sort((a, b) => (Math.random() > 0.5 ? 1 : -1)); //shuffle the array
  }
}
