import { Component } from "@angular/core";

@Component({
  selector: "app-print-slider-tpbrochure",
  templateUrl: "./print-slider-tpbrochure.component.html",
  styleUrls: ["./print-slider-tpbrochure.component.scss"],
})
export class PrintSliderTpbrochureComponent {
  slides = [
    { img: "assets/img/print/triplep-bro_01.webp" },
    { img: "assets/img/print/triplep-bro_02.webp" },
    { img: "assets/img/print/triplep-bro_03.webp" },
    { img: "assets/img/print/triplep-bro_04.webp" },
  ];

  breakpoints = [
    {
      breakpoint: 9999,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ];

  slideConfig = {
    dots: true,
    arrows: true,
    responsive: this.breakpoints,
  };

  breakpoint() {
    console.log("breakpoint");
  }
  constructor() {}
}
