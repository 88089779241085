import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-grailer-slider-logo',
  templateUrl: './grailer-slider-logo.component.html',
  styleUrls: ['./grailer-slider-logo.component.scss']
})
export class GrailerSliderLogoComponent implements OnInit {

  logos = [
    
    { background: '#B8AFA5',
      svg: "assets/img/grailer/grailer-logo-dark.svg" 
    },
    { background: '#5F5C59',
      svg: "assets/img/grailer/grailer-logo-light.svg" 
    }
    
  ];



  slideConfig = { 
    "dots": true, 
    "arrows": true,
  };

  constructor() { }

  ngOnInit() {
  }

}
