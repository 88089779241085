import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-ov-slider-poster",
  templateUrl: "./ov-slider-poster.component.html",
  styleUrls: ["./ov-slider-poster.component.scss"],
})
export class OvSliderPosterComponent {
  slides = [
    { img: "assets/img/ov/poster_dnb.webp" },
    { img: "assets/img/ov/poster_elektro.webp" },
    { img: "assets/img/ov/poster_hiphop.webp" },
  ];

  breakpoints = [
    {
      breakpoint: 9999,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ];

  slideConfig = {
    dots: true,
    arrows: true,
    responsive: this.breakpoints,
  };

  breakpoint() {
    console.log("breakpoint");
  }
  constructor() {}
}
