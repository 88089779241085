import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-random-stuff',
  templateUrl: './random-stuff.component.html',
  styleUrls: ['./random-stuff.component.scss']
})
export class RandomStuffComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
