import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-danvi-slider-logo",
  templateUrl: "./danvi-slider-logo.component.html",
  styleUrls: ["./danvi-slider-logo.component.scss"],
})
export class DanviSliderLogoComponent implements OnInit {
  logos = [
    { background: "#f2f2f2", svg: "assets/img/danvi/logo-danvi-dark.svg" },
    { background: "#3E5742", svg: "assets/img/danvi/logo-danvi-light.svg" },
  ];

  slideConfig = {
    dots: true,
    arrows: true,
  };
  constructor() {}

  ngOnInit() {}
}
