import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-kat-colours",
  templateUrl: "./kat-colours.component.html",
  styleUrls: ["./kat-colours.component.scss"],
})
export class KatColoursComponent {
  colours = [
    {
      swatch: "#111418",
      title: "Bunker",
      code: "#111418",
    },
    {
      swatch: "#3240A9",
      title: "Violet Blue",
      code: "#3240A9",
    },
    {
      swatch: "#F4F6F7",
      title: "White Smoke",
      code: "#F4F6F7",
    },
    {
      swatch: "#3137F8",
      title: "Palatinate Blue",
      code: "#3137F8",
    },
  ];

  constructor() {}

  ngOnInit() {}
}
