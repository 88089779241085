import { Component } from '@angular/core';

@Component({
  selector: 'app-vittaa-slider-logo',
  templateUrl: './vittaa-slider-logo.component.html',
  styleUrls: ['./vittaa-slider-logo.component.scss']
})
export class VittaaSliderLogoComponent {

  logos = [
    { background: '#F5F5F5',
      svg: "assets/img/vittaa/logo-vittaa-green.svg" 
    },
    { background: '#5AEE6F',
      svg: "assets/img/vittaa/logo-vittaa-white.svg" 
    },
  ];



  slideConfig = { 
    "dots": true, 
    "arrows": true,
  };

  constructor() { }

  ngOnInit() {
  }

}
