import { Component, OnInit } from "@angular/core";
import { ContentService } from "../../shared/services/content.service";
import { Meta } from "@angular/platform-browser";

@Component({
  selector: "app-logo-design",
  templateUrl: "./logo-design.component.html",
  styleUrls: ["./logo-design.component.scss"],
})
export class LogoDesignComponent implements OnInit {
  page: Object;

  constructor(private contentService: ContentService, private meta: Meta) {
    this.meta.addTags([
      { name: "title", content: "Overzicht van logo en wordmark design." },

      {
        name: "description",
        content:
          "Een overicht van logo-designs welke ik door de jaren heen heb ontworpen.",
      },
    ]);
  }

  ngOnInit() {
    this.page = this.contentService.pages["logo-design"];
  }

  logolist = [
    {
      logoImage: "assets/img/logo/logo-habibah.png",
      logoTitle: "Habibah",
      logoSubtitle: "Fashion",
    },
    {
      logoImage: "assets/img/logo/logo-knivesandtools.png",
      logoTitle: "Knives and Tools",
      logoSubtitle: "Ecommerce",
    },

    {
      logoImage: "assets/img/logo/logo-danvi.png",
      logoTitle: "Danvi",
      logoSubtitle: "Specialty Coffee",
    },

    {
      logoImage: "assets/img/logo/logo-mygoodz.png",
      logoTitle: "My Goodz",
      logoSubtitle: "Ecommerce",
      logoConcept: "*",
    },
    {
      logoImage: "assets/img/logo/logo-commplot.png",
      logoTitle: "Commplot",
      logoSubtitle: "Marketing Agency",
    },

    {
      logoImage: "assets/img/logo/logo-osmose.png",
      logoTitle: "Osmose",
      logoSubtitle: "Multicultural Consultancy",
    },
    {
      logoImage: "assets/img/logo/logo-dento.png",
      logoTitle: "Dento",
      logoSubtitle: "Kitchen Knives Series",
    },
    {
      logoImage: "assets/img/logo/logo-takara.png",
      logoTitle: "Takara",
      logoSubtitle: "Kitchen Knives Series",
    },

    {
      logoImage: "assets/img/logo/logo-vittaa.png",
      logoTitle: "Vittaa",
      logoSubtitle: "Online Sports Platform",
    },
    {
      logoImage: "assets/img/logo/logo-skerper.png",
      logoTitle: "Skerper",
      logoSubtitle: "Sharpening Tools",
    },
    {
      logoImage: "assets/img/logo/logo-zuboo.png",
      logoTitle: "Zuboo",
      logoSubtitle: "Ecommerce",
      logoConcept: "*",
    },
    {
      logoImage: "assets/img/logo/logo-lfg.png",
      logoTitle: "LFG",
      logoSubtitle: "Gaming Plaform",
    },

    {
      logoImage: "assets/img/logo/logo-grailer.png",
      logoTitle: "Grailer",
      logoSubtitle: "Exclusive Pocket Knives",
    },
    {
      logoImage: "assets/img/logo/logo-elp.png",
      logoTitle: "Elp",
      logoSubtitle: "Fashion",
      logoConcept: "*",
    },
    {
      logoImage: "assets/img/logo/logo-lekker-stevig.png",
      logoTitle: "Lekker Stevig",
      logoSubtitle: "Blog",
    },

    {
      logoImage: "assets/img/logo/logo-plixy.png",
      logoTitle: "Plixy",
      logoSubtitle: "Payment Service",
      logoConcept: "*",
    },

    {
      logoImage: "assets/img/logo/logo-tm.png",
      logoTitle: "Travel Marketing",
      logoSubtitle: "Travel Incentive agency",
      logoConcept: "*",
    },
  ];
}
