import { Component, OnInit } from '@angular/core';
import { ContentService } from '../../shared/services/content.service';

@Component({
  selector: 'app-grailer',
  templateUrl: './grailer.component.html',
  styleUrls: ['./grailer.component.scss']
})
export class GrailerComponent implements OnInit {
  page: Object;

  constructor(private contentService: ContentService) { }


  ngOnInit() {
    this.page = this.contentService.pages['grailer'];
  }

}
