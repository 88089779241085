import { Component } from "@angular/core";

@Component({
  selector: "app-kat-slider-mobile",
  templateUrl: "./kat-slider-mobile.component.html",
  styleUrls: ["./kat-slider-mobile.component.scss"],
})
export class KatSliderMobileComponent {
  slides = [
    { img: "assets/img/kat/kat_home_mob.webp" },
    { img: "assets/img/kat/kat_category_mob.webp" },
    { img: "assets/img/kat/kat_brand_mob.webp" },
    { img: "assets/img/kat/kat_product_mob.webp" },
    { img: "assets/img/kat/kat_product_spec1_mob.webp" },
    { img: "assets/img/kat/kat_product_spec2_mob.webp" },
    { img: "assets/img/kat/kat_topic_mob.webp" },
  ];

  breakpoints = [
    {
      breakpoint: 9999,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 2,
      },
    },
    {
      breakpoint: 1600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 800,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        centerMode: true,
        centerPadding: "0",
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ];

  slideConfig = {
    dots: true,
    responsive: this.breakpoints,
  };

  slickInit(e) {
    console.log("slick initialized");
  }

  breakpoint(e) {
    console.log("breakpoint");
  }

  afterChange(e) {
    console.log("afterChange");
  }

  beforeChange(e) {
    console.log("beforeChange");
  }

  constructor() {}

  ngOnInit() {}
}
