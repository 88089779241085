import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-danvi-slider-mobile",
  templateUrl: "./danvi-slider-mobile.component.html",
  styleUrls: ["./danvi-slider-mobile.component.scss"],
})
export class DanviSliderMobileComponent implements OnInit {
  slides = [
    { img: "assets/img/danvi/01_Home_Mobile.webp" },
    { img: "assets/img/danvi/02_Product_Overview_Mobile.webp" },
    {
      img: "assets/img/danvi/03_1_Product_Overview_Mobile_Modal_single_purchase.webp",
    },
    {
      img: "assets/img/danvi/03_2_Product_Overview_Mobile_Modal_subscription.webp",
    },
    { img: "assets/img/danvi/04_1_Product_Mobile_single_purchase.webp" },
    {
      img: "assets/img/danvi/04_2-2_Product_Mobile_Scroll_Modal_subscription.webp",
    },
    { img: "assets/img/danvi/05_Payment_Cart_Mobile.webp" },
    { img: "assets/img/danvi/06_Payment_Cart_Mobile_Login.webp" },
    { img: "assets/img/danvi/07_Payment_Pay_Mobile.webp" },
    { img: "assets/img/danvi/08_Payment_Mobile_Ready.webp" },
  ];

  breakpoints = [
    {
      breakpoint: 9999,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 2,
      },
    },
    {
      breakpoint: 1600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 800,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ];

  slideConfig = {
    dots: true,
    responsive: this.breakpoints,
  };

  slickInit(e) {
    console.log("slick initialized");
  }

  breakpoint(e) {
    console.log("breakpoint");
  }

  afterChange(e) {
    console.log("afterChange");
  }

  beforeChange(e) {
    console.log("beforeChange");
  }

  constructor() {}

  ngOnInit() {}
}
