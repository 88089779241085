import { Component } from "@angular/core";

@Component({
  selector: "app-danvi-colours",
  templateUrl: "./danvi-colours.component.html",
  styleUrls: ["./danvi-colours.component.scss"],
})
export class DanviColoursComponent {
  colours = [
    {
      swatch: "#2D3C31",
      title: "Celtic",
      code: "#2D3C31",
    },

    //{
    //  swatch: "#8C956B",
    //  title: "Avocado",
    //  code: "#8C956B",
    //},
    //{
    //  swatch: "#91614B",
    //  title: "Spicy Mix",
    //  code: "#91614B",
    //},
    //{
    //  swatch: "#F49846",
    //  title: "Sea Buckthorn",
    //  code: "#F49846",
    //},
  ];

  constructor() {}
}
