import { Component } from "@angular/core";

@Component({
  selector: "app-kat-slider-desktop",
  templateUrl: "./kat-slider-desktop.component.html",
  styleUrls: ["./kat-slider-desktop.component.scss"],
})
export class KatSliderDesktopComponent {
  slides = [
    { img: "assets/img/kat/kat_home_dt.webp" },
    { img: "assets/img/kat/kat_category_dt.webp" },
    { img: "assets/img/kat/kat_brand_dt.webp" },
    { img: "assets/img/kat/kat_product_dt.webp" },
    { img: "assets/img/kat/kat_topic_dt.webp" },
  ];

  breakpoints = [
    {
      breakpoint: 9999,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        centerMode: true,
        centerPadding: "0",
      },
    },
    {
      breakpoint: 1600,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        centerMode: true,
        centerPadding: "0",
      },
    },
    {
      breakpoint: 800,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        centerMode: true,
        centerPadding: "0",
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ];

  slideConfig = {
    dots: true,
    responsive: this.breakpoints,
  };
  constructor() {}

  ngOnInit() {}
}
