import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { ContentService } from "./shared/services/content.service";

import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { ParallaxModule } from "ngx-parallax";
import { ExternalUrlDirective } from "./external-url.directive";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";

import { HomeComponent } from "./pages/home/home.component";
import { WorkListComponent } from "./shared/blocks/work-list/work-list.component";
import { WorkComponent } from "./pages/work/work.component";
import { NavbarComponent } from "./shared/blocks/navbar/navbar.component";
import { HeaderComponent } from "./shared/blocks/header/header.component";
import { HomeMainSliderComponent } from "./shared/blocks/home-main-slider/home-main-slider.component";
import { SlickCarouselModule } from "ngx-slick-carousel";
import { VittaaAppComponent } from "./pages/vittaa-app/vittaa-app.component";
import { HeaderLogoComponent } from "./shared/blocks/header-logo/header-logo.component";
import { EdenKnivesComponent } from "./pages/eden-knives/eden-knives.component";
import { KatWebsiteComponent } from "./pages/kat-website/kat-website.component";
import { VittaaSliderMobileComponent } from "./shared/blocks/vittaa-slider-mobile/vittaa-slider-mobile.component";
import { VittaaBrandingComponent } from "./shared/blocks/vittaa-branding/vittaa-branding.component";
import { VittaaColoursComponent } from "./shared/blocks/vittaa-colours/vittaa-colours.component";
import { VittaaSliderLogoComponent } from "./shared/blocks/vittaa-slider-logo/vittaa-slider-logo.component";
import { VittaaTypoComponent } from "./shared/blocks/vittaa-typo/vittaa-typo.component";
import { VittaaSliderDesktopComponent } from "./shared/blocks/vittaa-slider-desktop/vittaa-slider-desktop.component";
import { VittaaIconsComponent } from "./shared/blocks/vittaa-icons/vittaa-icons.component";
import { KatTypoComponent } from "./shared/blocks/kat-typo/kat-typo.component";
import { KatBrandingComponent } from "./shared/blocks/kat-branding/kat-branding.component";
import { KatColoursComponent } from "./shared/blocks/kat-colours/kat-colours.component";
import { KatSliderLogoComponent } from "./shared/blocks/kat-slider-logo/kat-slider-logo.component";
import { KatSliderDesktopComponent } from "./shared/blocks/kat-slider-desktop/kat-slider-desktop.component";
import { FooterComponent } from "./shared/blocks/footer/footer.component";
import { KatSliderMobileComponent } from "./shared/blocks/kat-slider-mobile/kat-slider-mobile.component";
import { KatSliderBannerComponent } from "./shared/blocks/kat-slider-banner/kat-slider-banner.component";
import { GrailerComponent } from "./pages/grailer/grailer.component";
import { GrailerBrandingComponent } from "./shared/blocks/grailer-branding/grailer-branding.component";
import { GrailerSliderLogoComponent } from "./shared/blocks/grailer-slider-logo/grailer-slider-logo.component";
import { GrailerTypoComponent } from "./shared/blocks/grailer-typo/grailer-typo.component";
import { GrailerColoursComponent } from "./shared/blocks/grailer-colours/grailer-colours.component";
import { GrailerSliderDesktopComponent } from "./shared/blocks/grailer-slider-desktop/grailer-slider-desktop.component";
import { GrailerSliderMobileComponent } from "./shared/blocks/grailer-slider-mobile/grailer-slider-mobile.component";
import { LogoDesignComponent } from "./pages/logo-design/logo-design.component";
import { AboutComponent } from "./pages/about/about.component";
import { RandomStuffComponent } from "./shared/blocks/random-stuff/random-stuff.component";
import { AvailabilityComponent } from "./shared/blocks/availability/availability.component";
import { PrintComponent } from "./pages/print/print.component";
import { PrintSliderFlindersComponent } from "./shared/blocks/print-slider-flinders/print-slider-flinders.component";

import { PrintSliderKatmagazineComponent } from "./shared/blocks/print-slider-katmagazine/print-slider-katmagazine.component";
import { PrintSliderTpbrochureComponent } from "./shared/blocks/print-slider-tpbrochure/print-slider-tpbrochure.component";
import { PrintSliderTpflyerComponent } from "./shared/blocks/print-slider-tpflyer/print-slider-tpflyer.component";
import { DanviComponent } from "./pages/danvi/danvi.component";
import { Danvi2Component } from "./pages/danvi2/danvi2.component";
import { DanviBrandingComponent } from "./shared/blocks/danvi-branding/danvi-branding.component";
import { DanviBranding2Component } from "./shared/blocks/danvi-branding2/danvi-branding2.component";
import { DanviColoursComponent } from "./shared/blocks/danvi-colours/danvi-colours.component";
import { DanviColours2Component } from "./shared/blocks/danvi-colours2/danvi-colours2.component";
import { DanviSliderDesktopComponent } from "./shared/blocks/danvi-slider-desktop/danvi-slider-desktop.component";
import { DanviSliderLogoComponent } from "./shared/blocks/danvi-slider-logo/danvi-slider-logo.component";
import { DanviSliderMobileComponent } from "./shared/blocks/danvi-slider-mobile/danvi-slider-mobile.component";
import { DanviTypoComponent } from "./shared/blocks/danvi-typo/danvi-typo.component";
import { DanviLabelsComponent } from "./shared/blocks/danvi-labels/danvi-labels.component";
import { LogoAnimationComponent } from "./shared/blocks/logo-animation/logo-animation.component";
import { OndergrondsverbondComponent } from './pages/ondergrondsverbond/ondergrondsverbond.component';
import { OvSliderPosterComponent } from './shared/blocks/ov-slider-poster/ov-slider-poster.component';
import { OvSliderLogoComponent } from './shared/blocks/ov-slider-logo/ov-slider-logo.component';
import { OvBrandingComponent } from './shared/blocks/ov-branding/ov-branding.component';
import { OvColoursComponent } from './shared/blocks/ov-colours/ov-colours.component';
import { OvTypoComponent } from './shared/blocks/ov-typo/ov-typo.component';
import { OvSliderInstaComponent } from './shared/blocks/ov-slider-insta/ov-slider-insta.component';
import { ScrollTransformComponent } from './shared/blocks/scroll-transform/scroll-transform.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    WorkListComponent,
    WorkComponent,
    NavbarComponent,
    HeaderComponent,
    HomeMainSliderComponent,
    VittaaAppComponent,
    HeaderLogoComponent,
    EdenKnivesComponent,
    KatWebsiteComponent,
    VittaaSliderMobileComponent,
    VittaaBrandingComponent,
    VittaaColoursComponent,
    VittaaSliderLogoComponent,
    VittaaTypoComponent,
    VittaaSliderDesktopComponent,
    VittaaIconsComponent,
    KatTypoComponent,
    KatBrandingComponent,
    KatColoursComponent,
    KatSliderLogoComponent,
    KatSliderDesktopComponent,
    FooterComponent,
    KatSliderMobileComponent,
    KatSliderBannerComponent,
    GrailerComponent,
    GrailerBrandingComponent,
    GrailerSliderLogoComponent,
    GrailerTypoComponent,
    GrailerColoursComponent,
    GrailerSliderDesktopComponent,
    GrailerSliderMobileComponent,
    LogoDesignComponent,
    AboutComponent,
    RandomStuffComponent,
    AvailabilityComponent,
    PrintComponent,
    PrintSliderFlindersComponent,
    PrintSliderKatmagazineComponent,
    PrintSliderTpbrochureComponent,
    PrintSliderTpflyerComponent,
    ExternalUrlDirective,
    DanviComponent,
    Danvi2Component,
    DanviBrandingComponent,
    DanviBranding2Component,
    DanviColoursComponent,
    DanviColours2Component,
    DanviSliderDesktopComponent,
    DanviSliderLogoComponent,
    DanviSliderMobileComponent,
    DanviTypoComponent,
    DanviLabelsComponent,
    LogoAnimationComponent,
    OndergrondsverbondComponent,
    OvSliderPosterComponent,
    OvSliderLogoComponent,
    OvBrandingComponent,
    OvColoursComponent,
    OvTypoComponent,
    OvSliderInstaComponent,
    ScrollTransformComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    SlickCarouselModule,
    ParallaxModule,
  ],

  providers: [ContentService],
  bootstrap: [AppComponent],
})
export class AppModule {}
