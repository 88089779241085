import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-danvi-labels",
  templateUrl: "./danvi-labels.component.html",
  styleUrls: ["./danvi-labels.component.scss"],
})
export class DanviLabelsComponent implements OnInit {
  slides = [
    { img: "assets/img/danvi/label_pantera.png" },
    { img: "assets/img/danvi/label_sagui.png" },
    { img: "assets/img/danvi/label_tucano.png" },
    { img: "assets/img/danvi/label_gelada.png" },
    { img: "assets/img/danvi/label_capra_walie.png" },
    { img: "assets/img/danvi/label_capivara.png" },
    { img: "assets/img/danvi/label_elang_hitam.png" },
  ];

  breakpoints = [
    {
      breakpoint: 9999,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 2,
      },
    },
    {
      breakpoint: 1600,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 1200,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ];

  slideConfig = {
    dots: true,
    responsive: this.breakpoints,
  };

  slickInit(e) {
    console.log("slick initialized");
  }

  breakpoint(e) {
    console.log("breakpoint");
  }

  afterChange(e) {
    console.log("afterChange");
  }

  beforeChange(e) {
    console.log("beforeChange");
  }

  constructor() {}

  ngOnInit() {}
}
