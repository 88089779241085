import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ContentService } from '../../shared/services/content.service';
import { Meta } from '@angular/platform-browser';  




@Component({
  selector: 'app-vittaa-app',
  templateUrl: './vittaa-app.component.html',
  styleUrls: ['./vittaa-app.component.scss']
})
export class VittaaAppComponent implements OnInit {

  page: Object;

  constructor(private contentService: ContentService, private meta: Meta) { 

    this.meta.addTags([
      { name: 'title', content: 'App design voor vittaa.com een online meetup platform om samen te sporten.' },

      { name: 'description', content: 'Concept app-design voor een online meetup platform voor sporters om samen te fietsen, mountainbiken, hardlopen of hiken.' }
    ]); 

  }


  ngOnInit() {
    this.page = this.contentService.pages['vittaa-app'];
  }

}
