import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-grailer-slider-desktop",
  templateUrl: "./grailer-slider-desktop.component.html",
  styleUrls: ["./grailer-slider-desktop.component.scss"],
})
export class GrailerSliderDesktopComponent implements OnInit {
  slides = [
    { img: "assets/img/grailer/grailer_home_dt.webp" },
    { img: "assets/img/grailer/grailer_g3_dt.webp" },
    { img: "assets/img/grailer/grailer_about_dt.webp" },
  ];

  breakpoints = [
    {
      breakpoint: 9999,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        centerMode: true,
        centerPadding: "0",
      },
    },
    {
      breakpoint: 1600,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        centerMode: true,
        centerPadding: "0",
      },
    },
    {
      breakpoint: 800,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        centerMode: true,
        centerPadding: "0",
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ];

  slideConfig = {
    dots: true,
    responsive: this.breakpoints,
  };
  constructor() {}

  ngOnInit() {}
}
