import { Component } from "@angular/core";

@Component({
  selector: "app-kat-slider-banner",
  templateUrl: "./kat-slider-banner.component.html",
  styleUrls: ["./kat-slider-banner.component.scss"],
})
export class KatSliderBannerComponent {
  banners = [
    { img: "assets/img/kat/ban-christmas.webp" },
    { img: "assets/img/kat/ban-halloween.webp" },
    { img: "assets/img/kat/ban-mothersday.webp" },
    { img: "assets/img/kat/ban-blackfriday.webp" },
    { img: "assets/img/kat/ban-deal.gif" },
    { img: "assets/img/kat/ban-outfit.webp" },
    { img: "assets/img/kat/ban-easter.webp" },
    { img: "assets/img/kat/ban-singlesday.webp" },
    { img: "assets/img/kat/ban-outlet.webp" },
    { img: "assets/img/kat/ban-world.webp" },
    { img: "assets/img/kat/ban-allbright.webp" },
  ];

  breakpoints = [
    {
      breakpoint: 9999,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ];

  slideConfig = {
    dots: true,
    arrows: true,
    responsive: this.breakpoints,
  };

  breakpoint() {
    console.log("breakpoint");
  }
  constructor() {}
}
