import { Component, OnInit } from '@angular/core';
import { ContentService } from '../../shared/services/content.service';
import { Meta } from '@angular/platform-browser';  




@Component({
  selector: 'app-kat-website',
  templateUrl: './kat-website.component.html',
  styleUrls: ['./kat-website.component.scss']
})
export class KatWebsiteComponent implements OnInit {
  page: Object;

  constructor(private contentService: ContentService, private meta: Meta) { 

    this.meta.addTags([
      { name: 'title', content: 'Visual redesign voor een internationale webshop.' },

      { name: 'description', content: 'Volledig redesign voor de website van knivesandtools als in-house visual designer.' }
    ]); 

  }


  ngOnInit() {
    this.page = this.contentService.pages['kat-website'];
  }

}
