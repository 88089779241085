import { Component, OnInit } from "@angular/core";
import { ContentService } from "../../shared/services/content.service";
import { Meta } from "@angular/platform-browser";

@Component({
  selector: "app-print",
  templateUrl: "./print.component.html",
  styleUrls: ["./print.component.scss"],
})
export class PrintComponent implements OnInit {
  page: Object;

  constructor(private contentService: ContentService, private meta: Meta) {
    this.meta.addTags([
      {
        name: "title",
        content: "Print.",
      },

      {
        name: "description",
        content: "Print design.",
      },
    ]);
  }

  ngOnInit() {
    this.page = this.contentService.pages["print"];
  }
}
