import { Component } from "@angular/core";

@Component({
  selector: "app-vittaa-colours",
  templateUrl: "./vittaa-colours.component.html",
  styleUrls: ["./vittaa-colours.component.scss"],
})
export class VittaaColoursComponent {
  colours = [
    {
      swatch: "#23D93D",
      title: "Mantis",
      code: "#23D93D",
    },
    {
      swatch: "#4B296B",
      title: "Blue Diamond",
      code: "#4B296B",
    },
    {
      swatch: "#FDFEFD",
      title: "Smoke White",
      code: "#FDFEFD",
    },
  ];

  constructor() {}

  ngOnInit() {}
}
