import { Component, OnInit } from "@angular/core";
import { ContentService } from "../../shared/services/content.service";
import { Meta } from "@angular/platform-browser";

@Component({
  selector: "app-ondergrondsverbond",
  templateUrl: "./ondergrondsverbond.component.html",
  styleUrls: ["./ondergrondsverbond.component.scss"],
})
export class OndergrondsverbondComponent implements OnInit {
  page: Object;

  constructor(private contentService: ContentService, private meta: Meta) {
    this.meta.addTags([
      {
        name: "title",
        content: "",
      },

      {
        name: "description",
        content: "",
      },
    ]);
  }
  ngOnInit() {
    this.page = this.contentService.pages["ondergrondsverbond"];
  }
}
