import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-danvi-typo',
  templateUrl: './danvi-typo.component.html',
  styleUrls: ['./danvi-typo.component.scss']
})
export class DanviTypoComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
