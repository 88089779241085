import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-ov-slider-logo",
  templateUrl: "./ov-slider-logo.component.html",
  styleUrls: ["./ov-slider-logo.component.scss"],
})
export class OvSliderLogoComponent implements OnInit {
  logos = [
    {
      background: "linear-gradient(170deg, #ffcc44 33%, #e41b72 100%)",
      svg: "assets/img/ov/ov-logo-dark.svg",
    },
    {
      background: "linear-gradient(220deg, #009f80 33%, #5c2483 100%)",
      svg: "assets/img/ov/ov-logo-light.svg",
    },
  ];

  slideConfig = {
    dots: true,
    arrows: true,
  };
  constructor() {}

  ngOnInit() {}
}
