import { Component } from "@angular/core";

@Component({
  selector: "app-vittaa-slider-mobile",
  templateUrl: "./vittaa-slider-mobile.component.html",
  styleUrls: ["./vittaa-slider-mobile.component.scss"],
})
export class VittaaSliderMobileComponent {
  slides = [
    { img: "assets/img/vittaa/01_Home.webp" },
    { img: "assets/img/vittaa/02_Welcome.webp" },
    { img: "assets/img/vittaa/03_Create_activity_01-Type.webp" },
    { img: "assets/img/vittaa/04_Create_activity_02_Start.webp" },
    { img: "assets/img/vittaa/05_Create_activity_03_Date.webp" },
    { img: "assets/img/vittaa/06_Create_activity_03_Time.webp" },
    { img: "assets/img/vittaa/07_Create_activity_04_Fitness_Level.webp" },
    { img: "assets/img/vittaa/08_Create_activity_05_Distance_Elevation.webp" },
    { img: "assets/img/vittaa/09_Create_activity_06_Confirm.webp" },
    { img: "assets/img/vittaa/10_Activity_Created_07.webp" },
    { img: "assets/img/vittaa/11_Search_activity_01_Select.webp" },
    { img: "assets/img/vittaa/12_Search_activity_02_Select.webp" },
    { img: "assets/img/vittaa/13_Search_activity_00_Results.webp" },
    { img: "assets/img/vittaa/14_Activity_01.webp" },
    { img: "assets/img/vittaa/15_Activity_02.webp" },
    { img: "assets/img/vittaa/16_Activity_03.webp" },
    { img: "assets/img/vittaa/17_Activity_03B.webp" },
    { img: "assets/img/vittaa/18_Profile_01.webp" },
    { img: "assets/img/vittaa/19_Inbox_Notifications.webp" },
  ];

  breakpoints = [
    {
      breakpoint: 9999,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 2,
      },
    },
    {
      breakpoint: 1600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 800,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ];

  slideConfig = {
    dots: true,
    responsive: this.breakpoints,
  };

  slickInit(e) {
    console.log("slick initialized");
  }

  breakpoint(e) {
    console.log("breakpoint");
  }

  afterChange(e) {
    console.log("afterChange");
  }

  beforeChange(e) {
    console.log("beforeChange");
  }

  constructor() {}

  ngOnInit() {}
}
