import { Component, OnInit } from "@angular/core";
import { ContentService } from "../../shared/services/content.service";
import { Meta } from "@angular/platform-browser";

@Component({
  selector: "app-danvi",
  templateUrl: "./danvi.component.html",
  styleUrls: ["./danvi.component.scss"],
})
export class DanviComponent implements OnInit {
  page: Object;

  constructor(private contentService: ContentService, private meta: Meta) {
    this.meta.addTags([
      {
        name: "title",
        content: "Visual identity voor Danvi, een nieuw Specialty Coffee merk",
      },

      {
        name: "description",
        content:
          "Ontwerp voor de visuele identiteit van Danvi, een nieuw koffiemerk gevestigd in Wageningen. ",
      },
    ]);
  }
  ngOnInit() {
    this.page = this.contentService.pages["danvi"];
  }
}
