import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-grailer-branding',
  templateUrl: './grailer-branding.component.html',
  styleUrls: ['./grailer-branding.component.scss']
})
export class GrailerBrandingComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
