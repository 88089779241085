import { Component, HostListener, ElementRef, OnInit } from "@angular/core";

@Component({
  selector: "app-scroll-transform",
  templateUrl: "./scroll-transform.component.html",
  styleUrls: ["./scroll-transform.component.scss"],
})
export class ScrollTransformComponent implements OnInit {
  elem: HTMLElement;

  constructor(private elementRef: ElementRef) {}

  ngOnInit() {
    this.elem = this.elementRef.nativeElement.querySelector(".scroll_logo");
  }

  @HostListener("window:scroll", [])
  onWindowScroll() {
    const value = window.scrollY * 0.25;
    this.elem.style.transform = `translateX(-50%) translateY(-50%) rotate(${value}deg)`;
  }
}
