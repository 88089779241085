import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-ov-slider-insta",
  templateUrl: "./ov-slider-insta.component.html",
  styleUrls: ["./ov-slider-insta.component.scss"],
})
export class OvSliderInstaComponent {
  slides = [
    { img: "assets/img/ov/ov-insta_01.webp" },
    { img: "assets/img/ov/ov-insta_02.webp" },
    { img: "assets/img/ov/ov-insta_03.webp" },
    { img: "assets/img/ov/ov-insta_04.webp" },
    { img: "assets/img/ov/ov-insta_05.webp" },
  ];

  breakpoints = [
    {
      breakpoint: 9999,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 900,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 500,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ];

  slideConfig = {
    dots: true,
    arrows: true,
    responsive: this.breakpoints,
  };

  breakpoint() {
    console.log("breakpoint");
  }
  constructor() {}
}
