import { Component, HostListener, OnInit } from "@angular/core";

@Component({
  selector: "app-header-logo",
  templateUrl: "./header-logo.component.html",
  styleUrls: ["./header-logo.component.scss"],
})
export class HeaderLogoComponent implements OnInit {
  //  scrolled: boolean = false;

  //  @HostListener("window:scroll", [])
  //  onWindowScroll() {
  //    this.scrolled = window.scrollY > 32;
  //  }

  constructor() {}

  ngOnInit() {}
}
