import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-danvi-slider-desktop",
  templateUrl: "./danvi-slider-desktop.component.html",
  styleUrls: ["./danvi-slider-desktop.component.scss"],
})
export class DanviSliderDesktopComponent implements OnInit {
  slides = [
    { img: "assets/img/danvi/01_Home_Desktop.webp" },
    { img: "assets/img/danvi/02_Product_Overview_Desktop.webp" },
    {
      img: "assets/img/danvi/03_1_Product_Overview_Desktop_Modal_single_purchase.webp",
    },
    { img: "assets/img/danvi/04_1_Product_Desktop_Subscription.webp" },
    { img: "assets/img/danvi/04_2-2_Product_Desktop_Modal_subscription.webp" },
    { img: "assets/img/danvi/05_Payment_Cart_Desktop.webp" },
    { img: "assets/img/danvi/07_Payment_Pay_Desktop.webp" },
  ];

  breakpoints = [
    {
      breakpoint: 9999,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        centerMode: true,
        centerPadding: "0",
      },
    },
    {
      breakpoint: 1600,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        centerMode: true,
        centerPadding: "0",
      },
    },
    {
      breakpoint: 800,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        centerMode: true,
        centerPadding: "0",
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        centerMode: true,
        centerPadding: "0",
      },
    },
  ];

  slideConfig = {
    dots: true,
    responsive: this.breakpoints,
  };
  constructor() {}

  ngOnInit() {}
}
